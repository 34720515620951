exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-projects-tsx": () => import("./../../../src/pages/projects.tsx" /* webpackChunkName: "component---src-pages-projects-tsx" */),
  "component---src-pages-publications-tsx": () => import("./../../../src/pages/publications.tsx" /* webpackChunkName: "component---src-pages-publications-tsx" */),
  "component---src-pages-subscribe-confirm-index-tsx": () => import("./../../../src/pages/subscribe/confirm/index.tsx" /* webpackChunkName: "component---src-pages-subscribe-confirm-index-tsx" */),
  "component---src-pages-subscribe-index-tsx": () => import("./../../../src/pages/subscribe/index.tsx" /* webpackChunkName: "component---src-pages-subscribe-index-tsx" */),
  "component---src-pages-subscribe-thanks-index-tsx": () => import("./../../../src/pages/subscribe/thanks/index.tsx" /* webpackChunkName: "component---src-pages-subscribe-thanks-index-tsx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2017-dangerous-step-from-agile-to-fragile-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2017/dangerous-step-from-agile-to-fragile/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2017-dangerous-step-from-agile-to-fragile-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2017-docker-whale-in-digital-ocean-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2017/docker-whale-in-digital-ocean/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2017-docker-whale-in-digital-ocean-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2017-how-to-create-aot-jit-compatible-angular-4-library-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2017/how-to-create-aot-jit-compatible-angular-4-library/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2017-how-to-create-aot-jit-compatible-angular-4-library-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2017-if-you-ever-used-subway-you-should-know-what-binary-search-tree-is-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2017/if-you-ever-used-subway-you-should-know-what-binary-search-tree-is/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2017-if-you-ever-used-subway-you-should-know-what-binary-search-tree-is-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2017-solid-principles-around-you-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2017/solid-principles-around-you/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2017-solid-principles-around-you-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2018-aggregatus-service-is-live-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2018/aggregatus-service-is-live/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2018-aggregatus-service-is-live-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2018-algorithms-and-data-structures-in-javascript-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2018/algorithms-and-data-structures-in-javascript/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2018-algorithms-and-data-structures-in-javascript-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2018-dynamic-programming-vs-divide-and-conquer-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2018/dynamic-programming-vs-divide-and-conquer/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2018-dynamic-programming-vs-divide-and-conquer-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2018-extend-your-limits-rather-than-your-ambitions-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2018/extend-your-limits-rather-than-your-ambitions/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2018-extend-your-limits-rather-than-your-ambitions-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2018-gift-of-24-hours-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2018/gift-of-24-hours/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2018-gift-of-24-hours-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2018-homemade-machine-learning-in-python-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2018/homemade-machine-learning-in-python/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2018-homemade-machine-learning-in-python-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2018-links-to-promote-your-next-startup-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2018/links-to-promote-your-next-startup/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2018-links-to-promote-your-next-startup-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2018-machine-learning-in-matlaboctave-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2018/machine-learning-in-matlaboctave/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2018-machine-learning-in-matlaboctave-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2018-permutations-combinations-cheat-sheet-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2018/permutations-combinations-cheat-sheet/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2018-permutations-combinations-cheat-sheet-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2018-playground-and-cheatsheet-for-learning-python-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2018/playground-and-cheatsheet-for-learning-python/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2018-playground-and-cheatsheet-for-learning-python-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2018-playing-with-discrete-fourier-transform-algorithm-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2018/playing-with-discrete-fourier-transform-algorithm/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2018-playing-with-discrete-fourier-transform-algorithm-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2018-top-33-javascript-projects-on-github-august-2018-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2018/top-33-javascript-projects-on-github-august-2018/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2018-top-33-javascript-projects-on-github-august-2018-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2019-most-trending-jupyter-notebooks-of-december-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2019/most-trending-jupyter-notebooks-of-december/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2019-most-trending-jupyter-notebooks-of-december-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2019-nano-neuron-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2019/nano-neuron/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2019-nano-neuron-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2019-react-useposition-hook-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2019/react-useposition-hook/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2019-react-useposition-hook-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2019-technical-interview-preparation-checklist-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2019/technical-interview-preparation-checklist/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2019-technical-interview-preparation-checklist-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2020-coronavirus-covid-19-dashboard-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2020/coronavirus-covid-19-dashboard/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2020-coronavirus-covid-19-dashboard-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2020-machine-learning-experiments-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2020/machine-learning-experiments/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2020-machine-learning-experiments-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2020-printed-links-detection-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2020/printed-links-detection/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2020-printed-links-detection-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2020-recipes-generation-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2020/recipes-generation/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2020-recipes-generation-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2020-state-of-the-art-shitcode-principles-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2020/state-of-the-art-shitcode-principles/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2020-state-of-the-art-shitcode-principles-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2020-this-is-how-social-media-could-ruin-your-happiness-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2020/this-is-how-social-media-could-ruin-your-happiness/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2020-this-is-how-social-media-could-ruin-your-happiness-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2020-top-33-javascript-projects-on-github-december-index-md": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2020/top-33-javascript-projects-on-github-december/index.md" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2020-top-33-javascript-projects-on-github-december-index-md" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2021-binary-floating-point-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2021/binary-floating-point/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2021-binary-floating-point-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2021-content-aware-image-resizing-in-javascript-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2021/content-aware-image-resizing-in-javascript/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2021-content-aware-image-resizing-in-javascript-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2021-gyro-web-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2021/gyro-web/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2021-gyro-web-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2021-self-parking-car-evolution-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2021/self-parking-car-evolution/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2021-self-parking-car-evolution-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2021-top-33-javascript-projects-on-github-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2021/top-33-javascript-projects-on-github/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2021-top-33-javascript-projects-on-github-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2022-data-structure-sketches-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2022/data-structure-sketches/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2022-data-structure-sketches-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2022-okso-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2022/okso/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2022-okso-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2022-solid-principles-sketches-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2022/solid-principles-sketches/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2022-solid-principles-sketches-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-micrograd-ts-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2023/micrograd-ts/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-micrograd-ts-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2023-observations-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2023/observations/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2023-observations-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-api-design-x-home-timeline-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2024/api-design-x-home-timeline/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-api-design-x-home-timeline-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-src-posts-2024-system-design-sketches-index-mdx": () => import("./../../../src/templates/Post.tsx?__contentFilePath=/home/runner/work/trekhleb.github.io/trekhleb.github.io/src/posts/2024/system-design-sketches/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-src-posts-2024-system-design-sketches-index-mdx" */),
  "component---src-templates-project-tsx": () => import("./../../../src/templates/Project.tsx" /* webpackChunkName: "component---src-templates-project-tsx" */)
}

